import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, CTA } from "../components";

const Lenses = ({ data: { strapiMkoLenses } }) => {
  return (
    <PageLayout {...strapiMkoLenses} flourish={1}>
      <CTA />
    </PageLayout>
  );
};

export default Lenses;

export const query = graphql`
  query lensesPageQuery {
    strapiMkoLenses {
      content
      subtitle
      intro
      title
    }
  }
`;
